@use '../../../../../../styles/shared/include' as *;

.missingPhoto {
  @media #{$lg-m} {
    // The missing photo look awful and pointless on mobile, so we hide it
    display: none;
  }

  object-fit: cover;
  max-width: 500px;
  margin: 0 auto;
  path {
    fill: $deepBlue;
  }
}
