@import '../../../../styles/shared/include';

.AgencyTeamAboutContainer {
	.teamDescription {
		@media #{$lg} {
			display: flex;
			flex-direction: row;
		}

		.professionalDetails {
			background-color: $cream;

			figure {
				border-bottom: 1px solid transparentize($deepBlue, 0.6);

				object-fit: cover;
				img {
					width: 100%;
					height: 100%;
				}
			}

			@media #{$lg} {
				flex-basis: 41.8%;
				width: 41.8%;
			}

			.basicDetails {
				padding: rem(40);
				color: $deepBlue;

				h3 {
					font-size: rem(28);
					font-weight: 300;
					line-height: 100%;
					padding-bottom: rem(16);
				}
				p {
					font-size: rem(16);
					font-weight: 500;
					line-height: 140%;
					padding-bottom: rem(4);
					@media #{$lg} {
						font-size: rem(18);
					}
				}
				a {
					font-size: rem(16);
					font-weight: 500;
					line-height: 140%;
					@media #{$lg} {
						font-size: rem(18);
					}
				}
			}
		}

		article {
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: $deepBlue;
			color: $white;
			padding: rem(40);
			@media #{$lg} {
				padding: rem(112) rem(120);
				flex-basis: 58.2%;
				width: 58.2%;
			}

			h3 {
				font-size: rem(32);
				line-height: 100%;
				font-weight: 300;
				padding-bottom: rem(24);
				@media #{$lg} {
					font-size: rem(40);
				}
			}

			p {
				font-size: rem(16);
				font-weight: 500;
				line-height: 140%;
				margin: 0;
				+ p {
					margin-top: rem(25.2);
				}
				@media #{$xl} {
					font-size: rem(18);
					min-width: rem(600);
				}
			}
		}

		&--design2 {
			@media #{$lg} {
				flex-direction: row-reverse;
			}
			article {
				background-color: $white;
				color: $deepBlue;
			}
			@media #{$lg} {
				flex-direction: row-reverse;
			}
		}
	}

	&:nth-child(odd) .teamDescription {
		@media #{$lg} {
			flex-direction: row-reverse;
		}
	}
}
