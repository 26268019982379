@use '../../styles/shared/include' as *;

.states__listing {
	&:nth-of-type(1) {
		padding-top: rem(60);
		@media #{$xxl} {
			padding-top: rem(120);
		}
	}
	:global {
		.title__div {
			margin-bottom: rem(50);
			padding: rem(10) rem(30);
			border-bottom: rem(1) solid $periwinkleGrey;
			font-size: rem(22);
			line-height: 120%;
			font-weight: 600;
			color: $deepBlue;
			@media #{$xxl} {
				font-size: rem(24);
				margin-bottom: rem(80);
				padding: rem(14.4) rem(52);
			}
			h2 {
				line-height: inherit;
				font-size: inherit;
				font-weight: 400;
			}
		}
		ul {
			display: grid;
			justify-content: space-between;
			column-gap: rem(12);
			grid-template-columns: 100%;
			@media #{$nm} {
				grid-template-columns: 46% 46%;
			}
			@media #{$md} {
				column-gap: rem(20);
				grid-template-columns: 30% 30% 30%;
			}
			@media #{$lg} {
				grid-template-columns: 32.5% 32.5% 32.5%;
			}
			@media #{$xl} {
				grid-template-columns: rem(300) rem(300) rem(300);
			}
			@media #{$xxl} {
				grid-template-columns: rem(320) rem(320) rem(320);
			}
			li {
				margin-bottom: rem(60);
				display: block;
				padding-bottom: rem(12);
				border-bottom: rem(2) solid $periwinkleGrey;
				padding-right: rem(30);
				position: relative;
				@include transition();
				@media #{$xxl} {
					padding-bottom: rem(16);
					margin-bottom: rem(120);
				}
				a {
					font-size: 0;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				h3 {
					font-size: rem(16);
					line-height: 120%;
					font-weight: 600;
					margin-bottom: rem(10);
					color: $deepBlue;
					letter-spacing: rem(-0.4);
					@media #{$lg} {
						font-size: rem(18);
					}
					@media #{$xxl} {
						font-size: rem(24);
					}
				}
				P {
					font-size: rem(14);
					line-height: 120%;
					font-weight: 400;
					margin-bottom: 0;
					color: $deepBlue;
					@media #{$lg} {
						font-size: rem(18);
					}
					@media #{$xxl} {
						font-size: rem(24);
					}
				}
				figure {
					width: rem(20);
					position: absolute;
					right: 0;
					top: rem(4);
					@media #{$xxl} {
						width: rem(24);
					}
					.svg {
						width: 100%;
						height: auto;
					}
				}
				&:hover {
					border-color: $deepBlue;
				}
			}
		}
	}
	&:first-of-type(1) {
		:global {
			.title__div {
				margin-top: rem(120);
			}
		}
	}
}
