@use '../../styles/shared/include' as *;

.leadership__hero {
	padding: rem(50) 0 rem(30);
	overflow: hidden;
	@media #{$md} {
		padding: rem(112) 0 rem(64);
	}
	background-color: $deepBlue;
	:global {
		.container--max {
			position: relative;
		}
		.hero__logo-layer {
			position: absolute;
			right: rem(-2);
			top: calc(50% - 24px);
			transform: translateY(-50%);
			width: rem(100);
			display: none;
			@media #{$md-1} {
				display: block;
			}
			@media #{$lg} {
				width: rem(141);
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		.hero__content {
			@media #{$lg} {
				max-width: rem(1000);
			}
			h2 {
				color: $brightYellow;
				font-size: rem(16);
				line-height: 125%;
				font-weight: 800;
				letter-spacing: rem(2);
				text-transform: uppercase;
				font-family: $secondary;
				margin-bottom: rem(20);
				@media #{$md} {
					letter-spacing: rem(2.4);
					margin-bottom: rem(24);
				}
				&:empty {
					display: none;
				}
			}
			h1 {
				color: $white;
				font-size: rem(48);
				line-height: 100%;
				font-weight: 300;
				margin-bottom: rem(20);
				@media #{$md} {
					font-size: rem(72);
					margin-bottom: rem(24);
					letter-spacing: rem(-4);
				}
			}
			p {
				color: $white;
				font-size: rem(16);
				line-height: 140%;
				font-weight: 500;
				a {
					text-decoration: underline;
					&:hover {
						color: $brightYellow;
					}
				}
				@media #{$md} {
					letter-spacing: rem(0.3);
					font-size: rem(18);
				}
				& + p {
					margin-bottom: rem(25.2);
				}
				& + a {
					margin-top: rem(12);
					@media #{$md} {
						margin-top: rem(32);
					}
				}
			}
		}
	}
	&--bannerDesign {
		padding: rem(112) 0;
		:global {
			.hero__content {
				min-height: rem(176);
				display: flex;
				flex-direction: column;
				justify-content: center;
				@media #{$md-1} {
					max-width: rem(735);
				}
				h2 {
					@media #{$md} {
						margin-bottom: rem(17.6);
					}
				}
				h1 {
					@media #{$md} {
						font-size: rem(64);
						letter-spacing: 0;
					}
				}
			}
			.hero__logo-layer {
				top: 50%;
			}
		}
	}
}
