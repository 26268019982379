@use '../../../../styles/shared/include' as *;

.insuranceTextColWithSideLogoSection {
	background-color: $deepBlue;
	.insuranceTextColWithSideLogo {
		color: white;
		justify-content: space-between;
		@media #{$md} {
			display: flex;
		}
		.textSection {
			padding: rem(50) rem(30);

			@media #{$md} {
				padding: rem(90) rem(150) rem(90) rem(90);
			}
			@media #{$xl} {
				padding: rem(112) rem(120);
			}
			h3 {
				font-size: rem(40);
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(24);
				max-width: rem(960);
				@media #{$lgn} {
					font-size: rem(72);
				}
			}
			p {
				margin-bottom: rem(23);
				max-width: rem(960);
			}
			h5 {
				padding-top: rem(24);
				font-size: rem(18);
			}
		}
		.logoSection {
			display: none;
			@media #{$md} {
				display: flex;
				padding: rem(52) 0;
				align-self: center;
				img {
					height: rem(328);
					width: rem(156);
				}
			}
		}
	}
}
