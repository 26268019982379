@use '../../../../styles/shared/include' as *;

.imageColTextFormSection {
	:global {
		.container {
			&--max {
				padding: 0;
			}
		}
	}
	.imageColTextForm {
		@media #{$lgn} {
			display: flex;
			flex-direction: row-reverse;
			min-height: calc(100vh - (80px + var(--announcement-banner-height)));
		}
		.textSection {
			padding: rem(48) rem(20);
			color: $deepBlue;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@media #{$lgn} {
				width: 41.66%;
				padding: rem(67.5) rem(120);
			}
			width: 100%;

			h1 {
				font-size: rem(40);
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(24);
				color: $deepBlue;
				@media #{$lgn} {
					font-size: rem(64);
				}
				@media #{$xxl} {
					font-size: rem(72);
				}

				& span {
					font-weight: 800;
					color: inherit;
				}
			}

			p {
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
				padding-bottom: rem(40);
				color: $deepBlue;

				& span {
					font-weight: 800;
					color: inherit;
				}
			}
			form {
				fieldset {
					label {
						margin-bottom: rem(5);
						display: inline-block;
						font-weight: 500;
						line-height: 125%;
						color: $black;
					}
					select {
						width: 100%;
						margin-bottom: rem(24);
						border: rem(1) solid $periwinkleGrey;
						border-radius: rem(3);
						font-size: rem(16);
						font-weight: 500;
						padding: rem(12) rem(20);
						line-height: 125%;
						appearance: none;
						-webkit-appearance: none;
						background: $dropdown no-repeat calc(100% - rem(24));
					}
					input {
						width: 100%;
						margin-bottom: rem(10);
						border: rem(1) solid $periwinkleGrey;
						border-radius: rem(3);
						font-size: rem(16);
						font-weight: 500;
						padding: rem(12) rem(20);
					}
					span {
						display: block;
						height: rem(22);
						color: red;
						margin-bottom: rem(8);
					}
					.formHeading {
						color: $deepBlue;
						font-size: rem(20);
						font-style: normal;
						font-weight: 500;
						line-height: rem(20);
						margin-bottom: rem(16);
					}
				}
			}
			.formTagline {
				padding-bottom: rem(16);
				font-size: rem(20);
				font-weight: 500;
				line-height: 100%;
			}
		}
		.imageSection {
			height: auto;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			@media #{$lgn} {
				width: 58.33%;
			}
		}

		&--imageSmallDesign {
			@media #{$lgn} {
				.textSection {
					width: 58.33%;
				}
				.imageSection {
					width: 41.66%;
				}
			}
		}

		&--imageWithFormHeading {
			.textSection {
				margin-bottom: rem(42);
				h1 {
					@media #{$md} {
						font-size: rem(64);
						margin-top: rem(63);
					}
					@media #{$lgn} {
						min-width: rem(601);
					}
				}
			}
			@media #{$lgn} {
				.textSection {
					width: 58.33%;
				}
				.imageSection {
					width: 41.66%;
				}
			}
		}
	}
}
