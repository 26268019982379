@use '../../../styles/shared/include' as *;

.blog_single__post {
	display: flex;
	flex-wrap: wrap;
	@media #{$lg} {
		align-items: center;
	}
	:global {
		figure {
			width: calc((360 / 1440) * 100%);
			// background-color: $deepBlue;
			@media #{$nm-m} {
				width: 100%;
			}
			img {
				width: 100%;
				height: 100%;
				min-width: auto !important;
				object-fit: contain;
				@media #{$lg} {
					height: unset;
				}
			}
		}
		article {
			width: calc((1080 / 1440) * 100%);
			padding: rem(10) rem(20);
			display: flex;
			flex-direction: column;
			@media #{$nm-m} {
				width: 100%;
				padding: rem(20) rem(0);
			}
			@media #{$lg} {
				padding: rem(30) rem(40);
			}
			ul {
				display: flex;
				align-items: center;
				li {
					color: $deepBlue;
					font-size: rem(12);
					line-height: 140%;
					font-weight: 600;
					margin-bottom: rem(6);
					text-transform: uppercase;
					@media #{$lg} {
						margin-bottom: rem(12);
						letter-spacing: rem(0.96);
					}
					&:after {
						content: '|';
						display: inline-block;
						margin: 0 rem(5);
					}
					&:last-child {
						&::after {
							display: none;
						}
					}
				}
			}
			h2 {
				color: $deepBlue;
				font-size: rem(20);
				line-height: 120%;
				font-weight: 400;
				margin-bottom: rem(10);
				@media #{$lg} {
					font-size: rem(24);
					margin-bottom: rem(16);
				}
			}
			p {
				color: $deepBlue;
				font-size: rem(14);
				line-height: 140%;
				font-weight: 500;
			}
		}
	}
}

.blog__listing {
	padding: rem(60) 0;
	@media #{$xl} {
		padding: rem(120) 0;
	}
	:global {
		.listing__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-bottom: rem(16);
			border-bottom: rem(1) solid $periwinkleGrey;
			@media #{$lg} {
				padding-bottom: rem(24);
			}
			h2 {
				color: $deepBlue;
				font-size: rem(18);
				line-height: 120%;
				font-weight: 500;
				margin-bottom: rem(20);
				@media #{$nm} {
					margin-bottom: 0;
				}
				@media #{$lg} {
					font-size: rem(20);
				}
			}
			.blog__sort {
				display: flex;
				align-items: center;
				gap: rem(10);
				p {
					color: $deepBlue;
					font-size: rem(14);
					line-height: 140%;
					font-weight: 400;
					@media #{$lg} {
						font-size: rem(18);
					}
				}
				label {
					color: $deepBlue;
					font-size: rem(14);
					line-height: 140%;
					font-weight: 400;
					@media #{$lg} {
						font-size: rem(18);
					}
				}
				select {
					color: $deepBlue;
					font-size: rem(16);
					line-height: 140%;
					font-weight: 600;
					padding-right: rem(24);
					padding-left: rem(5);
					position: relative;
					-webkit-appearance: none;
					background-image: $downArrowSelect;
					background-size: rem(24);
					background-position: right;
					background-repeat: no-repeat;
					background-color: $white;
					cursor: pointer;
					// outline: none;
					&:not(:focus),
					a:not(:focus-within),
					a:not(:focus-visible) {
						focus: none;
					}
					@media #{$lg} {
						font-size: rem(18);
					}
				}
			}
		}
		.blog__list_wrapper {
			& > li {
				padding: rem(20) 0;
				border-bottom: rem(1) solid $periwinkleGrey;
				@media #{$nm-m} {
					padding-bottom: 0;
				}
				@media #{$lg} {
					padding: rem(40) 0;
				}
				a {
					width: 100%;
				}
			}
		}
		.blog__pagination {
			padding-top: rem(60);
			max-width: rem(334);
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media #{$nm-m} {
				width: 90%;
				padding-top: rem(50);
			}
			@media #{$xl} {
				padding-top: rem(120);
			}
			a {
				display: block;
				padding: 0 !important;
				width: rem(36) !important;
				height: rem(36);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $deepBlue;
				@include transition();
				cursor: pointer;
				font-size: 0;
				position: relative;
				&::after {
					content: '';
					width: rem(12);
					height: rem(12);
					border-bottom-right-radius: rem(3);
					border-right: rem(2) solid $white;
					border-bottom: rem(2) solid $white;
					transform: rotate(-45deg);
					position: relative;
					left: rem(-2);
					@include transition();
				}
				&:hover {
					background-color: $brightYellow;
					&::after {
						border-color: $black;
					}
				}
				&.is-disabled,
				&:disabled {
					background-color: #c4c4c4;
					pointer-events: none;
				}
				&:first-child {
					&::after {
						transform: rotate(135deg);
						left: rem(2);
					}
				}
			}
			p {
				display: block;
				text-align: center;
				font-size: rem(18);
				line-height: 140%;
				font-weight: 400;
				color: $deepBlue;
			}
		}
	}
	.no_image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: rem(10);
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: rem(14);
		line-height: 120%;
		color: $white;
		background-color: $deepBlue;
	}
}
