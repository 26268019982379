@use '../../../../styles/shared/include' as *;

.imageColTextFormSection {
	.imageColTextForm {
		display: flex;
		flex-direction: column-reverse;
		@media #{$lgn} {
			flex-direction: row;
		}
		.textSection {
			padding: rem(48) rem(20);
			color: $deepBlue;
			@media #{$lgn} {
				width: 41.66%;
				padding: rem(67.5) rem(120);
			}
			width: 100%;
			flex-direction: column;

			.mainHeading {
				font-size: rem(40);
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(24);
				color: $deepBlue;
				@media #{$lgn} {
					font-size: rem(72);
				}
			}

			p {
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
				padding-bottom: rem(46);
				color: $deepBlue;
			}

			fieldset {
				label {
					font-weight: 500;
					line-height: 125%;
					color: $black;
					display: inline-block;
					padding-bottom: rem(5);
				}
				select {
					width: 100%;
					margin-bottom: rem(24);
					border: rem(1) solid $periwinkleGrey;
					border-radius: rem(3);
					font-size: rem(16);
					font-weight: 500;
					padding: rem(12) rem(20);
					line-height: 125%;
					appearance: none;
					-webkit-appearance: none;
					background: $dropdown no-repeat calc(100% - rem(24));
				}
				input {
					width: 100%;
					margin-bottom: rem(10);
					border: rem(1) solid $periwinkleGrey;
					border-radius: rem(3);
					font-size: rem(16);
					font-weight: 500;
					padding: rem(12) rem(20);
				}
				span {
					display: block;
					height: rem(24);
					margin-bottom: rem(8);
					color: red;
				}
				// button {
				// 	width: auto;
				// 	padding: rem(10) rem(20);
				// 	border: none;
				// 	cursor: pointer;
				// }
				.formHeading {
					color: $deepBlue;
					font-size: rem(20);
					font-style: normal;
					font-weight: 500;
					line-height: rem(20);
					margin-bottom: rem(16);
				}
			}
			.formTagline {
				display: block;
				padding-bottom: rem(16);
				font-size: rem(20);
				font-weight: 500;
				line-height: 100%;
			}
		}
		.imageSection {
			height: auto;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			@media #{$lgn} {
				width: 58.33%;
			}
		}

		&--imageSmallDesign {
			@media #{$lgn} {
				.textSection {
					width: 58.33%;
				}
				.imageSection {
					width: 41.66%;
				}
			}
		}

		&--imageWithFormHeading {
			.textSection {
				margin-bottom: rem(42);
				.mainHeading {
					@media #{$md} {
						font-size: rem(64);
						margin-top: rem(63);
					}
				}
			}
			@media #{$lgn} {
				.textSection {
					width: 58.33%;
				}
				.imageSection {
					width: 41.66%;
				}
			}
		}
	}
}
