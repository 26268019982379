@use '../../../styles/shared/include' as *;

.agencyHomeTextButtoncontainerSection {
	background-color: $deepBlue;

	.agencyHomeTextButtoncontainer {
		@media #{$lgn} {
			display: flex;
			flex-direction: row;
		}
		.imageContainer {
			width: 100%;
			height: 100%;
			@media #{$lgn} {
				max-width: rem(600);
				overflow: hidden;
				height: auto;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		article {
			color: $white;
			@media #{$sm} {
				padding: rem(48) rem(20);
			}
			@media #{$md} {
				padding: rem(50);
			}
			@media #{$xl} {
				padding: rem(112) rem(120);
				width: 90%;
			}

			h2 {
				padding-bottom: rem(24);
				font-size: rem(40);
				font-weight: 300;
				line-height: 100%;
				word-wrap: normal;
				@media #{$lg} {
					font-size: rem(48);
				}
			}
			p {
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
				b {
					font-style: normal;
					font-weight: 500;
					font-size: rem(18);
					color: $brightYellow;
				}
				&:not(:last-child) {
					padding-bottom: rem(25);
				}
			}
			@media #{$xl} {
				font-size: rem(18);
				p {
					min-width: 600px;
				}
			}

			@media #{$xl} {
				font-size: rem(18);
			}
		}
	}
}
