$bodySize: 16;
$bodyFontSize: 16px;

@function em($value, $base: 16) {
	$newValue: $value / $base * 1em;
	@return $newValue;
}
@function rem($value) {
	$newValue: ($value / $bodySize) * 1rem;
	@return $newValue;
}
@function px2em($px) {
	@return ($px / $bodyFontSize) * 1em;
}
@mixin font-size($font-size, $line-height: false) {
	font-size: px2em($font-size);
	@if $line-height != false {
		line-height: ($line-height / $font-size);
	}
}

// line clamp
@mixin clamp-lines($lineCount) {
	display: -webkit-box;
	-webkit-line-clamp: $lineCount;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
//Transition
@mixin transition(
	$properties: all,
	$duration: 0.3s,
	$timing-function: ease-in-out
) {
	transition: $properties $duration $timing-function;
}

//hide and show
@mixin is-hidden() {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}
@mixin is-visible() {
	visibility: visible;
	opacity: 1;
	pointer-events: all;
}

// line clamp
@mixin clamp-lines($lineCount) {
	display: -webkit-box;
	-webkit-line-clamp: $lineCount;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
//Transition
@mixin transition(
	$properties: all,
	$duration: 0.3s,
	$timing-function: ease-in-out
) {
	transition: $properties $duration $timing-function;
}

//hide and show
@mixin is-hidden() {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}
@mixin is-visible() {
	visibility: visible;
	opacity: 1;
	pointer-events: all;
}

$xs: 'screen and (min-width:' + (320px) + ')';
$sm: 'screen and (min-width:' + (375px) + ')';
$nm: 'screen and (min-width:' + (425px) + ')';
$sml: 'screen and (min-width:' + (640px) + ')';
$md: 'screen and (min-width:' + (769px) + ')';
$md-1: 'screen and (min-width:' + (992px) + ')';
$lg: 'screen and (min-width:' + (1024px) + ')';
$lgn: 'screen and (min-width:' + (1150px) + ')';
$xl: 'screen and (min-width:' + (1280px) + ')';
$xlg: 'screen and (min-width:' + (1360px) + ')';
$xxl: 'screen and (min-width:' + (1440px) + ')';
$vl: 'screen and (min-width:' + (1920px) + ')';

$lgn-m: 'screen and (max-width:' + (1149px) + ')';
$lg-m: 'screen and (max-width:' + (1023px) + ')';
$md-m: 'screen and (max-width:' + (768px) + ')';
$sml-m: 'screen and (max-width:' + (640px) + ')';
$nm-m: 'screen and (max-width:' + (480px) + ')';
