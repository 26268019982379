@use '../../../../styles/shared/include' as *;

.insuranceShortBannerWithKeyValueSection {
	background-color: $brightYellow;

	.insuranceShortBannerWithKeyValue {
		display: flex;
		flex-direction: column;
		padding: rem(40);
		color: $deepBlue;
		gap: rem(40);
		@media #{$md} {
			flex-direction: row;
			justify-content: flex-start;
			gap: 0;
		}
		@media #{$lgn} {
			padding: rem(36.5) rem(120);
		}
		.section {
			@media #{$md} {
				flex: 1;
			}
		}
		address {
			font-style: normal;
			font-size: rem(24);
			font-weight: 500;
			line-height: 140%;
		}

		.keyHeading {
			font-family: Exo;
			font-size: rem(12);
			font-weight: 800;
			line-height: rem(20);
			letter-spacing: rem(1.8);
			text-transform: uppercase;
			margin-bottom: rem(4);
		}

		.value {
			font-size: rem(24);
			font-weight: 500;
			line-height: 140%;
			.link {
				text-decoration-line: underline;
			}
		}
	}
}
