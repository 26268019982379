@use '../../../styles/shared/include' as *;

.blog__detail {
	padding: rem(60) 0;
	@media #{$lg} {
		padding: rem(80) 0;
	}
	:global {
		img {
			max-width: 100%;
		}

		// Temporary fix to allow vertical spacing
		hr {
			visibility: hidden;
		}

		.blog__detail_wrapper {
			max-width: rem(960);
			margin: 0 auto;
			.blog__detail_header {
				ul.blog__type{
					display: flex;
					align-items: center;
					margin-bottom: rem(16);
					li{
						color: $deepBlue;
						font-weight: 600;
						font-size: rem(12);
						text-transform: uppercase;
						line-height: 140%;
						letter-spacing: rem(0.96);
						&:after{
							content: '|';
							display: inline-block;
							margin: 0 rem(5);
						}
						&:last-child{
							&::after{
								display: none;
							}
						}
					}
				}
				h1 {
					color: $deepBlue;
					font-weight: 300;
					font-size: rem(28);
					line-height: 100%;
					margin-bottom: rem(10);
					@media #{$lg} {
						margin-bottom: rem(20);
						font-size: rem(48);
					}
				}
				.blog__date {
					color: $deepBlue;
					font-weight: 500;
					font-size: rem(14);
					line-height: 140%;
					margin-bottom: rem(0);
				}
				.blog__share {
					margin-top: rem(20);
					display: flex;
					gap: rem(20);
					@media #{$lg} {
						margin-top: rem(40);
					}
					li {
						a {
							width: rem(20);
							height: rem(20);
							.svg {
								width: 100%;
								height: auto;
								path {
									fill: $periwinkleGrey;
								}
							}
							&:hover {
								.svg {
									path {
										fill: $deepBlue;
									}
								}
							}
						}
					}
				}
			}
			.blog__feature_img {
				width: 100%;
				margin: rem(26) 0;
				position: relative;
				top: rem(-5);
				@media #{$lg} {
					margin: rem(48) 0;
				}
				img {
					width: 100%;
					object-fit: cover;
					max-height: rem(540);
				}
			}
			.blog__details {
				max-width: rem(720);
				margin: 0 auto;
			}
			.content__wrapper {
				position: relative;
				top: rem(-3);
				p {
					color: $black;
					@include font-size(16px);
					font-weight: 400;
					line-height: 177.78%;
					@media #{$lg} {
						font-size: rem(18);
					}
					a {
						color: $deepBlue;
						text-decoration: underline;
						&:hover {
							color: $black;
						}
					}
					& + p {
						margin-top: rem(15);
						@media #{$lg} {
							margin-top: rem(24);
						}
					}
				}
				blockquote {
					p {
						color: $deepBlue;
						@include font-size(20px);
						font-weight: 400;
						line-height: 132.85%;
						margin: rem(20) 0;
						@media #{$md} {
							line-height: 142.85%;
							margin: rem(48) 0;
							font-size: rem(28);
						}
					}
				}
				ul {
					padding-left: rem(20);
					margin: rem(32) 0;
					@media #{$md} {
						margin: rem(48) 0;
					}
					li {
						list-style-type: disc;
						margin-bottom: rem(10);
						color: $black;
						@include font-size(16px);
						font-weight: 400;
						line-height: 127.78%;
						@media #{$lg} {
							font-size: rem(18);
						}
						a {
							color: $deepBlue;
							text-decoration: underline;
							&:hover {
								color: $black;
							}
						}
					}
				}
				ol {
					padding-left: rem(20);
					margin: rem(32) 0;
					@media #{$md} {
						margin: rem(48) 0;
					}
					li {
						list-style-type: number;
						margin-bottom: rem(10);
						color: $black;
						@include font-size(16px);
						font-weight: 400;
						line-height: 127.78%;
						@media #{$lg} {
							font-size: rem(18);
						}
						a {
							color: $deepBlue;
							text-decoration: underline;
							&:hover {
								color: $black;
							}
						}
					}
				}
				hr {
					border: none;
					border-bottom: rem(1) solid $deepBlue;
					margin: rem(20) 0;
					@media #{$md} {
						margin-bottom: rem(48);
					}
					@media #{$lg} {
						margin-bottom: rem(72);
					}

				}
				h3 {
					color: $deepBlue;
					@include font-size(16px);
					font-weight: 600;
					line-height: 177.78%;
					margin-bottom: rem(8);
					@media #{$md} {
						@include font-size(18px);
					}
				}
				h2 {
					color: $deepBlue;
					@include font-size(18px);
					font-weight: 600;
					line-height: 177.78%;
					margin-bottom: rem(8);
					@media #{$md} {
						@include font-size(24px);
					}
				}
				h4,
				h5,
				h6 {
					color: $deepBlue;
					@include font-size(16px);
					font-weight: 600;
					line-height: 177.78%;
					margin-bottom: rem(8);
					@media #{$md} {
						@include font-size(18px);
					}
				}
			}
			.blog_abt__company {
				max-width: rem(720);
				margin: 0 auto;
				hr{
					border: none;
					border-bottom: rem(1) solid $deepBlue;
					margin: rem(20) 0;
					@media #{$md} {
						margin-bottom: rem(48) 0;
					}
				}
				h3,h4{
					color: $deepBlue;
					@include font-size(18px);
					font-weight: 600;
					line-height: 177.78%;
					margin-bottom: rem(8);
					@media #{$md} {
						@include font-size(24px);
					}
				}
				p {
					color: $deepBlue;
					@include font-size(16px);
					font-weight: 400;
					line-height: 177.78%;
					@media #{$lg} {
						font-size: rem(18);
					}
					a {
						color: $deepBlue;
						text-decoration: underline;
						&:hover {
							color: $black;
						}
					}
					& + p {
						margin-top: rem(15);
						@media #{$lg} {
							margin-top: rem(24);
						}
					}
				}
			}
		}
	}
}
