@use '../../../../styles/shared/include' as *;

.teamSectionContainer {
	background-color: $deepBlue;

	.teamSection {
		color: white;
		justify-content: space-between;
		@media #{$md} {
			display: flex;
		}
		article {
			padding: rem(50) rem(30);
			@media #{$md} {
				padding: rem(90) rem(150) rem(90) rem(90);
			}
			@media #{$xxl} {
				padding: rem(112) rem(264) rem(112) rem(120);
			}
			h3 {
				font-size: rem(64);
				font-weight: 300;
				line-height: 100%;
			}
			p {
				padding-top: rem(24);
				font-size: rem(18);
			}
			.buttonContainer {
				color: $deepBlue;
				width: rem(208);
				padding-top: rem(28);
			}
		}
		.logoSection {
			display: none;
			@media #{$md} {
				display: block;
				padding: rem(52) 0;
			}
		}
	}
	.aboutCards {
		background-color: $deepBlue;
		border-top: solid rem(1) $white;
		@media #{$md} {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
		.aboutContainer {
			position: relative;
			padding: rem(40) rem(40) rem(32) rem(40);
			border-right: rem(1) solid $white;
			border-left: rem(1) solid $white;
			border-bottom: rem(1) solid white;
			@media #{$lg} {
				border-left: none;
				border-right: none;
				&::after {
					top: 0;
					right: 0;
					content: '';
					height: 100%;
					width: rem(1);
					background-color: $white;
					position: absolute;
				}
			}
			background-color: $deepBlue;
			width: 100%;
			.ImageContainer {
				width: 100%;
				aspect-ratio: 1;
				margin-bottom: rem(24);
				position: relative;
				background-color: $cream;
				min-height: 250px;

				.employeePhoto {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: top;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
				}
			}

			article {
				text-align: start;
				color: $white;
				span {
					font-size: rem(28);
					font-weight: 300;
					line-height: 100%;
					padding-bottom: rem(8);
					display: block;
				}
				p {
					font-size: rem(18);
					margin-bottom: rem(8);
					font-weight: 500;
					line-height: 140%;
				}
				a {
					font-size: rem(18);
					margin-bottom: rem(8);
					font-weight: 500;
					line-height: 140%;
				}
				// p {
				// 	font-size: rem(18);
				// }
			}
			@media #{$md} {
				width: 50%;
			}
			@media #{$lg} {
				width: 33.3%;
			}
		}
	}
}
