@use '../../../styles/shared/include' as *;

.blog__hero{
  isolation: isolate;
  padding: rem(70) 0;
  overflow-x: hidden;
  @media #{$md}{
    padding: rem(100) 0;
  }
  @media #{$xl}{
    padding: rem(143) 0;
  }
  :global{
    .container--max{
      position: relative;
    }
    .hero__logo-layer{
      z-index: -1;
      position: absolute;
      right: rem(-2);
      top: calc(50% - 24px);
      transform: translateY(-50%);
      img{
        max-width: rem(100);
        display: none;
        @media #{$md}{
          display: block;
        }
        @media #{$lg}{
          max-width: rem(141);
        }
      }
    }
    .hero__content{
      max-width: rem(600);
      h1{
        color: $deepBlue;
        font-size: rem(48);
        line-height: 100%;
        font-weight: 300;
        margin-bottom: rem(20);
        @media #{$md}{
          font-size: rem(64);
          margin-bottom: rem(24);
          letter-spacing: rem(-4);
        }
      }
      p{
        color: $deepBlue;
        font-size: rem(16);
        line-height: 140%;
        font-weight: 500;
        @media #{$md}{
          letter-spacing: rem(0.3);
          font-size: rem(18);
        }
        &+p{
          margin-bottom: rem(25.2);
        }
      }
    }
  }
}