@use '../../../../styles/shared/include' as *;

.agencyHomeTextButtoncontainerSection {
	background-color: $cream;

	.agencyHomeTextButtoncontainer {
		@media #{$lgn} {
			display: flex;
			flex-direction: row;
		}
		.imageContainer {
			width: 100%;
			height: 100%;
			@media #{$lgn} {
				max-width: rem(600);
				overflow: hidden;
				height: auto;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		article {
			@media #{$sm} {
				padding: rem(48) rem(20);
			}
			@media #{$md} {
				padding: rem(50);
			}
			@media #{$xl} {
				padding: rem(112) rem(120);
				width: 90%;
			}
			color: $deepBlue;
			h2 {
				font-family: $secondary;
				padding-bottom: rem(16);
				font-size: rem(16); //14
				font-weight: 800;
				line-height: 125%;
				letter-spacing: rem(2.4);
				text-transform: uppercase;
			}
			@media #{$xl} {
				font-size: rem(16);
			}

			p {
				padding-bottom: rem(27);
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
			}
			@media #{$xl} {
				font-size: rem(18);
				p {
					min-width: 600px;
				}
			}

			ul {
				padding-bottom: rem(24);
				font-size: rem(16);
				font-weight: 500;
				line-height: 140%;
				li {
					list-style: disc;
					list-style-position: inside;
					p {
						display: inline-block;
					}
				}
			}
			@media #{$xl} {
				font-size: rem(18);
			}

			// for content from agency api
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				span {
					font-style: italic;
					color: inherit;
					display: inline;
				}
			}
		}
	}
}
