@use '../../../../styles/shared/include' as *;

.textListSection {
	:global {
		.container {
			&--max {
				padding: 0;
			}
		}
	}
	.insuranceTextListSection {
		display: flex;
		flex-direction: column;
		color: $deepBlue;
		background-color: $white;
		@media #{$md} {
			flex-direction: row;
		}
		article {
			padding: rem(40);
			width: 100%;
			border-bottom: rem(1) solid $deepBlue;

			@media #{$md} {
				width: 58.33%;
				flex-basis: 58.33%;
				padding: rem(50);
				border-right: rem(1) solid $deepBlue;
				border-bottom: none;
			}
			@media #{$lgn} {
				padding: rem(100) rem(120);
			}
			@media #{$xxl} {
				padding: rem(148) rem(120) rem(112) rem(120);
			}

			h2 {
				font-size: rem(40);
				margin-bottom: rem(24);
				font-weight: 300;
				line-height: 100%;
				@media #{$lgn} {
					font-size: rem(64);
				}
			}
			p {
				margin-bottom: rem(24);
			}
		}
		.listWrapper {
			width: 100%;
			@media #{$md} {
				width: 41.67%;
				flex-basis: 41.67%;
				align-self: center;
			}
		}
		.listContent {
			padding: rem(20) rem(40);
			min-height: rem(140.75);
			display: flex;
			align-items: center;
			h3 {
				font-size: rem(28);
				font-weight: 300;
				line-height: 100%;
			}

			&:not(:last-child) {
				border-bottom: rem(1) solid $deepBlue;
			}
		}
		.buttonContainer {
			gap: rem(12);
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			@media #{$nm} {
				flex-direction: row;
			}
			@media #{$md} {
				flex-wrap: nowrap;
				width: fit-content;
			}
		}
	}
}
