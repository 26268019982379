$primary: 'DM Sans';
$secondary: 'Exo';
$secondary-inter: 'Inter';

//colour
$black: #000;
$brightYellow: #f0ff00;
$cream: #ffffe6;
$deepBlue: #003049;
$mutedBlue: #4F5E78;
$periwinkleGrey: #8291ac;
$white: #fff;
$hoverBlue: #012335;
