@use '../../styles/shared/include' as *;

.step__sec {
	background-color: $deepBlue;
	overflow: hidden;
	:global {
		.step__sec-wrapper {
			display: flex;
			flex-direction: column;
			.step__sec-col {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row-reverse;
				margin-bottom: rem(30);
				@media #{$md} {
					margin-bottom: 0;
				}
				.step__sec-content {
					width: 100%;
					padding: rem(36) rem(20);
					order: 2;
					@media #{$md} {
						order: unset;
						width: calc((840 / 1440) * 100%);
						padding: rem(60) rem(70);
					}
					@media #{$xl} {
						padding: rem(112) rem(120);
					}
					h3 {
						font-size: rem(14);
						color: $white;
						line-height: 125%;
						font-weight: 800;
						font-family: $secondary;
						margin-bottom: rem(8);
						text-transform: uppercase;
						letter-spacing: 2.4px;
						@media #{$md} {
							font-size: rem(16);
							margin-bottom: rem(16);
						}
						&:empty {
							display: none;
						}
					}
					h2 {
						font-size: rem(40);
						color: $brightYellow;
						line-height: 100%;
						font-weight: 300;
						margin-bottom: rem(8);
						@media #{$md} {
							font-size: rem(52);
							margin-bottom: rem(16);
						}
						@media #{$xl} {
							font-size: rem(64);
						}
						&:empty {
							display: none;
						}
					}
					p {
						color: $white;
						font-size: rem(16);
						line-height: 140%;
						font-weight: 500;
						margin: 0;
						@media #{$md} {
							font-size: rem(18);
						}
						&:empty {
							display: none;
						}
					}
				}
				.step__sec-img {
					width: 100%;
					order: 1;
					@media #{$md} {
						order: unset;
						width: calc((600 / 1440) * 100%);
						height: auto;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				&--even {
					padding: 0;
					align-items: flex-end;
					flex-direction: row;
					@media #{$md} {
						padding: rem(60) 0;
						padding-right: 0;
					}
					@media #{$xl} {
						padding: rem(112) 0;
						padding-right: 0;
					}
					.step__sec-content {
						width: 100%;
						padding: rem(30) rem(20);
						@media #{$md} {
							width: calc((600 / 1440) * 100%);
							padding: 0;
							padding-right: rem(40);
							padding-left: rem(70);
						}
						@media #{$xl} {
							padding-right: rem(120);
							padding-left: rem(120);
						}
						h2 {
							@media #{$xl} {
								margin-bottom: rem(24);
							}
						}
					}
					.step__sec-img {
						width: 100%;
						@media #{$md} {
							width: calc((840 / 1440) * 100%);
						}
					}
				}
			}
			.step__sec--title {
				.step__sec-content {
					width: 100%;
					padding-bottom: rem(30);
					@media #{$md} {
						padding-bottom: rem(64);
					}
					h2 {
						color: $white;
						margin: 0;
					}
				}
			}
			.step__sec--highlight {
				padding: 0;
				position: relative;
				.step__sec-content {
					padding: rem(30) rem(20);
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
					@media #{$md} {
						width: 90%;
						padding: rem(60);
						margin-left: 0;
						margin-right: auto;
					}
					@media #{$xl} {
						padding: rem(112) rem(120);
						min-height: rem(192);
					}
					h2 {
						width: rem(960);
						max-width: 90%;
						color: $white;
						b {
							font-weight: 300;
							color: $brightYellow;
						}
					}
				}
				.step__sec-img {
					position: absolute;
					right: rem(-2);
					top: 47%;
					transform: translateY(-50%);
					max-width: rem(100);
					@media #{$xl} {
						max-width: rem(141);
					}
					img {
						display: none;
						@media #{$md} {
							min-height: unset;
							object-fit: unset;
							margin-left: auto;
							max-width: rem(100);
							display: block;
						}
						@media #{$xl} {
							max-width: rem(141);
						}
					}
				}
				p {
					max-width: rem(960);
				}

				&--subSection {
					.step__sec-content {
						h2 {
							color: $brightYellow !important;
						}
					}
				}
			}
		}
	}
}
