@use '../../../styles/shared/include' as *;

.blog__post {
	.featured-article {
		display: flex;
		flex-direction: row;
		border: rem(1) solid $periwinkleGrey;
		flex-wrap: wrap;

		img {
			min-height: 0;
			min-width: 0;

			@media #{$lg} {
				max-width: 50%;
			}
			aspect-ratio: auto;
			height: auto;
		}

		article {
			padding: rem(20);
			flex: 1;
			display: flex;
			flex-direction: column;

			@media #{$sml-m} {
				font-size: rem(16);
			}
			@media #{$lg} {
				padding: rem(48);
			}
			ul {
				display: flex;
				flex: 0 0;
				align-items: center;
				li {
					color: $deepBlue;
					font-size: rem(12);
					line-height: 140%;
					font-weight: 600;
					margin-bottom: rem(16);
					letter-spacing: rem(0.96);
					text-transform: uppercase;
					&:after {
						content: '|';
						display: inline-block;
						margin: 0 rem(5);
					}
					&:last-child {
						&::after {
							display: none;
						}
					}
				}
			}

			h2 {
				flex: 1 1;
				overflow: hidden;
				color: $deepBlue;
				font-size: rem(22);
				line-height: 120%;
				font-weight: 400;
				margin-bottom: rem(30);
				@media #{$lg} {
					flex-basis: 0;
				}
				@media #{$xl} {
					font-size: rem(32);
				}
				mask-image: linear-gradient(to bottom, black, 98%, transparent);
			}

			p {
				color: $deepBlue;
				font-size: rem(14);
				line-height: 140%;
				font-weight: 500;
			}
		}
	}

	:global {
		.container a {
			display: block;
		}
		h2 {
			color: $deepBlue;
			font-size: rem(18);
			line-height: 120%;
			font-weight: 500;
			margin-bottom: rem(16);
			@media #{$lg} {
				font-size: rem(20);
				margin-bottom: rem(24);
			}
		}
	}
}
